@font-face {
  font-family: "Kanit-Bold";
  src: url("assets/fonts/Kanit/Kanit-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kanit-SemiBold";
  src: url("assets/fonts/Kanit/Kanit-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Medium";
  src: url("assets/fonts/Kanit/Kanit-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Regular";
  src: url("assets/fonts/Kanit/Kanit-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Light";
  src: url("assets/fonts/Kanit/Kanit-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Extra-Light";
  src: url("assets/fonts/Kanit/Kanit-ExtraLight.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Thin";
  src: url("assets/fonts/Kanit/Kanit-Thin.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM-SemiBold";
  src: url("assets/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM-Bold";
  src: url("assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM-Medium";
  src: url("assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM-Regular";
  src: url("assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM-Medium";
  src: url("assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM-Light";
  src: url("assets/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf");
  font-display: swap;
}

:root {
  --dominos-red-primary: #e31836;
  --domios-red-secondary: #e80045;
  --dominos-blue-primary: #0078ae;
  --dominos-blue-secondary: #0078ad;
  --dominos-black: #000000;
  --green: #109f0e;

  --gray-primary: #f2f2f2;
  --gray-dark: #878686;

  --dominos-white: #ffffff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
  letter-spacing: 0.3px !important;
}

input {
  font-size: 16px !important;
}

.Kanit-Bold {
  font-family: "Kanit-Bold" !important;
  letter-spacing: 0.3;
}

.Kanit-SemiBold {
  font-family: "Kanit-SemiBold" !important;
  letter-spacing: 0.3px;
}

.Kanit-Medium {
  font-family: "Kanit-Medium" !important;
}

.Kanit-Regular {
  font-family: "Kanit-Regular" !important;
}

.Kanit-Light {
  font-family: "Kanit-Light" !important;
}

.Kanit-Extra-Light {
  font-family: "Kanit-Extra-Light" !important;
}

.Kanit-Thin {
  font-family: "Kanit-Thin" !important;
}

/* IBM font */
.IBM-SemiBold {
  font-family: "IBM-SemiBold" !important;
}

.IBM-Bold {
  font-family: "IBM-Bold" !important;
}

.IBM-Medium {
  font-family: "IBM-Medium" !important;
}

.IBM-Light {
  font-family: "IBM-Light" !important;
}

.css-wr6es-MuiPaper-root-MuiAccordion-root::before {
  display: none !important;
}

.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999999999;
  background: #000000bd;
}

.side-bar-scroll:-webkit-scrollbar {
  width: 7px;
}

body {
  background-color: var(--dominos-white);
  font-family: "Kanit-Regular" !important;
  color: #000000 !important;
  scroll-behavior: smooth;
}

.body::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track {
  background: white;
}
body::-webkit-scrollbar-thumb {
  background: var(--dominos-blue-primary) !important;
  border-radius: 100px;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 100px;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

@media (max-width: 600px) {
  .body::-webkit-scrollbar {
    width: 0px;
  }
  *::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  * {
    user-select: initial !important;
  }
}

input[type="password"]::-ms-reveal {
  display: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

a:focus {
  color: var(--dominos-blue-primary);
}

.text-blue {
  color: var(--dominos-blue-primary);
}

.text-red {
  color: var(--dominos-red-primary);
}

.text-white {
  color: var(--dominos-white);
}

.text-black {
  color: var(--dominos-black);
}

.text-gray {
  color: var(--gray-dark);
}

.text-green {
  color: var(--green);
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-400 {
  font-weight: 400 !important;
}

.fs-500 {
  font-weight: 500 !important;
}

.fs-600 {
  font-weight: 600 !important;
}

h1 {
  font-size: 48px;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 24px;
  }
}

h2 {
  font-size: 20px;
}

.padding-inline {
  padding-inline: 50px;
}

@media screen and (max-width: 600px) {
  .padding-inline {
    padding-inline: 0;
  }
}

.text-trauncate-moblie {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-trauncate-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-trauncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-trauncate:hover {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.hover-underline:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.header-mobile {
  padding-block: 14px;
  padding-inline: 22px;
  left: 0;
  right: 0;
  background: white;
  z-index: 25;
  box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.488);
}

.header {
  padding-block: 14px;
  padding-inline: 22px;
  background-color: var(--dominos-white);
}

.header-tooltip {
  cursor: pointer;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icons {
  display: flex;
  gap: 22px;
  align-items: center;
}

.header-icons div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  /* width: 60px; */
}

.header-icons p {
  color: var(--dominos-black);
}

.header-icons img {
  width: 35px;
  height: 35px;
}

.user-tooltip {
  padding: 0;
  margin: 0;
}

.user-tooltip .list_item_text {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-start;
  padding-block: 14px;
  font-size: 14px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  text-wrap: nowrap;
  width: 300px;
  font-family: "IBM-Regular" !important;
  cursor: pointer;
  text-align: start;
  margin-right: auto;
  color: #494949;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .user-tooltip .list_item_text {
    width: 100%;
  }
}

.user-tooltip li:hover {
  background-color: #efecece1;
  transition: 0.5s;
  color: var(--dominos-red-primary);
}

.user-tooltip li:hover .list_item_icon {
  color: var(--dominos-red-primary);
}
.user-tooltip li:hover .list_item_text {
  color: var(--dominos-red-primary);
}

.list_item_icon {
  color: #636363;
  padding-left: 22px !important;
}
@media screen and (max-width: 600px) {
  .list_item_icon {
    padding-left: 40px !important;

  }
}

.header_list_item {
  background-color: rgba(239, 236, 236, 0.881);
}

.header_list_item .list_item_icon {
  background-color: rgba(239, 236, 236, 0.881);
  color: var(--dominos-red-primary);
}
.header_list_item .list_item_text {
  background-color: rgba(239, 236, 236, 0.881);
  color: var(--dominos-red-primary);
}

.header_list_item li {
  color: var(--dominos-red-primary);
}

.banner {
  background-color: var(--dominos-white);
}

.banner-inner {
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
}

@media (max-width: 900px) {
  .banner-inner {
    border-radius: 0px;
  }
}

.categories-main {
  background-color: var(--dominos-white);
}

.category-title {
  font-size: 20px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.3px !important;
}

/* footer start */

.footer-main {
  background-color: var(--dominos-white);
  padding-block: 4px;
  border-top: 1px solid lightgrey;
}

.footer-content {
  display: grid;
  grid-template-columns: 1.6fr 3fr 1fr;
  align-items: flex-end;
}

.footer-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 900px) {
  .footer-content {
    grid-template-columns: 1.6fr 1fr;
    justify-content: center;
  }
  .footer-list-container {
    display: grid;
    grid-template-columns: 0.6fr 0.8fr;
  }
}

@media (max-width: 1200px) {
  .footer-content {
    flex-wrap: nowrap;
  }
}

.footer-list li {
  margin-bottom: 8px;
  color: var(--dominos-blue-secondary) !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  cursor: pointer;
  list-style: none;
  padding: 0;
}

@media (max-width: 1200px) {
  .footer-list li {
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
}

.span-li {
  color: var(--dominos-blue-secondary) !important;
}

.active-link {
  color: var(--domios-red-secondary);
}

.footer-icons-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 42px;
  font-size: 16px;
  color: var(--dominos-blue-primary);
}

.footer-social-sec {
  display: flex;
  flex-direction: column;
  gap: 42px;
  color: var(--dominos-blue-primary);
  margin-top: 32px;
}

.footer-social-sec a:active {
  color: var(--dominos-blue-primary);
}

.footer-social-sec p {
  color: var(--dominos-blue-primary);
}

/* footer end */

/* mobile login */

.mobile_banner {
  background-color: #fff;
  background-image: url("../src/assets/images/login/backgroundRegister.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  object-fit: cover;
  width: 100%;
  z-index: -1;
  /* overflow: hidden; */
}

.Login_banner_Mobile {
  background-color: #fff;
  background-image: url("../src/assets/images/login/backgroundRegister.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  object-fit: cover;
  width: 100%;
  z-index: -1;
  /* overflow: hidden; */
}

.heading {
  padding-top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0000007a;
}

.loginInput {
  width: 65%;
}

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

/* mobile login ends */

.login_banner {
  background-image: url("../src/assets/images/login/mainbanner-blue.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  object-fit: cover;
  width: 100%;
  height: calc(100vh - 100px);
}

@media screen and (max-width: 900px) {
  .login_banner {
    height: 80vh;
  }
}

/* google map css */

.google-map-autocomplete {
  width: 100%;
}

.google-map-autocomplete input {
  outline: none;
  border: none;
  width: 100%;
}

.google-map-autocomplete input:focus {
  outline: none;
  border: none;
}

.store-map::-webkit-scrollbar-thumb {
  background: #8e8e8e !important;
}

/* google map css */

/* home page slider */

.slick-slide {
  padding: 10px;
}

@media screen and (max-width: 900px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.slick-next:before,
.slick-prev:before {
  display: none !important;
}

.slick-slider {
  padding-right: 4px;
}

/* home page slider */

.input-fields {
  width: 65%;
  margin-top: 15px;
}

/* -------------------Side Bar-------------------------- */

.sideBar_containe {
  width: 240px;
  background-color: #f7f7f7;
  height: 100dvh;
  position: relative;
}

.side_bar_top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top_flag-icon {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
}

.top_flag-icon img {
  width: 26px;
  margin-right: 10px;
}

.list_item_ul li {
  margin-bottom: 10px;
  /* margin-left: 18px; */
  list-style: none;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 20px;
}

.list_item {
  margin-top: 10px;
}

.footer {
  margin-top: 100px;
}

/* -------------------Side Bar Ends-------------------------- */

.sub_category_mobile {
  padding-block: 10;
  margin-top: 25px;
  overflow: auto;
}

/* Bottom Navbar for mobile */

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--dominos-blue-primary);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  z-index: 1301;
}

/* ------------------EMPTY CARD--------------------- */

.empty_card {
  background: white;
  width: 100%;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.empty_btn {
  background-color: var(--dominos-red-primary);
  border-radius: 10px;
  height: 51px;
  box-shadow: none;
  font-size: 16px;
  width: 80%;
}

.empty_btn:hover {
  background-color: var(--dominos-red-primary);
}

.cart-decs {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* for google maps */
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;
}

.gm-style .gm-style-iw-tc {
  position: relative;
  bottom: 20px;
  left: 0px;
  background-color: red;
  display: none;
}

.gm-style-iw > button {
  display: none !important;
}
.gm-ui-hover-effect {
  display: none !important;
}

/* for google maps */

.css-7g9vyu-MuiPaper-root-MuiAccordion-root::before {
  background-color: white;
}

.notification-mobile {
  background: rgb(255 255 255);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 95%;
  flex-direction: column;
  text-align: center;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.not-available-text-shadow {
  text-shadow: 1px 2px black;
}

.coupon-container {
  background-color: #0078ac;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 14px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 18px;
}
.coupon-error-container {
  background-color: #d2112c !important;
}

.coupon-container > .coupon-text {
  color: white;
  font-family: "Kanit-Light";
  text-transform: uppercase !important;
  font-size: 18px;
}
@media screen and (max-width: 600px) {
  .coupon-container > .coupon-text {
    font-size: 12px;
  }
}

.coupon-container > .coupon-action-icon {
  color: white;
  cursor: pointer;
  font-size: 22px;
}

.subCategory {
  position: sticky;
  z-index: 2;
  background: white;
  padding-top: 5px;
  padding-bottom: 10px;
}

.offer-not-available {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #0000007d;
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 10px;
}

.pizza-extra-price {
  color: red !important;
  margin-right: 20px !important;
}

.scrollable-div {
  height: 330px;
  overflow: auto;
}

.starting-from {
  color: rgb(73 73 73 / 70%);
  font-size: 16px;
  margin-bottom: -10px !important;
}

.scroll-loader {
  width: 48px;
  height: 48px;
  border: 5px dotted lightgrey;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-height: 550px) {
  .scrollable-div {
    height: 250px;
  }
}

@media (max-height: 450px) {
  .scrollable-div {
    height: 150px;
  }
}

@media (max-height: 730px) and (min-height: 600px) {
  .scrollable-div {
    height: 320px;
  }
}

.scrollable {
  height: 330px;
  overflow: auto;
}

@media (max-height: 500px) {
  .scrollable {
    height: 220px;
  }
}

@media (max-height: 850px) and (min-height: 770px) {
  .scrollable {
    height: 500px;
  }
}

@media (max-height: 1200px) and (min-height: 850px) {
  .scrollable {
    height: 515px;
  }
}
.offer-extra-price {
  position: absolute;
  right: 140px;
  top: 110px;
  transform: translateY(-190%);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .offer-extra-price {
    right: 70px;
  }
}
@media screen and (max-width: 650px) {
  .offer-extra-price {
    right: 20px;
    top: 150px;
    transform: translateY(-440%);
  }
}
.address_pill_containner {
  margin-top: 12px;
  max-height: 70%;
  overflow: auto;
}

@media screen and (max-width: 900px) {
  .address_pill_containner {
    max-height: 70%;
  }
}
/* iPhone SE */
@media screen and (max-width: 375px) {
  .address_pill_containner {
    max-height: 50%;
  }
}
/* iPhone 14 Pro */
@media screen and (max-width: 430px) and (min-height: 844px) {
  .address_pill_containner {
    max-height: 65%;
  }
}
.join-us-table-h4 {
  width: 261px;
  text-align: center;
}
.join_us_from_h1{
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  margin-top: 20px;
}